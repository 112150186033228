import pyramid from './oyrodo7js0d01.jpg';
import "./App.css";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={pyramid} className="App-logo" alt="logo" />
      </header>
      <h1>PYRAMID RECORDS</h1>

      <h2 className="annoying">THIS NIGERIAN PRINCE NEEDS YOUR HELP!!!! SEND ₹1,000 NOW!</h2>

      <p>
      Pyramid Records was founded in 1956 by Seymour McSlick after his father's goat breeding operation was shut down due to safety concerns.<br></br>
      Always a crafty individual, Seymour forged a new path-- one that would lead him to the top of the charts.
      <br></br><br></br>
      In 1959, Seymour took a trip to Arabia to learn from the great record producer DJ DonJuanOnDatBeat. <br/> It was under his tutelage that Seymour learned the art of music production, most notably how to craft a jaw-dropping producer tag.
      <br></br><br></br>
      In the 1960's, Pyramid Records was the first label to sign the Beatles. McSlick crafted hit after hit with the band, including: 
      <br></br><br></br>
      McSlick's next big act was a band by the name of Led Zeppelin-- a name which he suggested to the members. 
      <br></br><br></br>
      In the late 70's, McSlick had a string of hit bands, including, but not limited to: the Eagles, the Ravens, the Crows, the Hawks, and the Bluejays. <br/> He also produced some lesser known bands, including the Who, Lynyrd Skynyrd, the Rolling Stones, and Fleetwood Mac, and solo acts such as Prince, King, Queen, and Bruce Stringbean.
      
      <br></br><br></br>
      In the early 90's, Pyramid Records suffered a temporary slump. With record sales declining, McSlick toured the country in search of a fresh new sound. 
      <br></br>
      The result: Nirvana's Nevermind album smashed global records and ushered in a new era of music. 
      <br></br><br></br>
      McSlick's next move was to sign a young rapper by the name of Tupac Shakur. He worked with the rapper until his untimely death in 1996. 
      <br></br>      <br></br>
      The loss of a dear friend sent Seymour into a deep depression, and he did not return to music until early 2011-- <br></br> when he wrote and produced the smash hit "Sexy and I Know It" by LMFAO.
      <br></br>Collaborators said McSlick's personal life experience was essential to the record's authenticity.

      <br></br>      <br></br>
      McSlick's comeback continued, with hits including "I Miss My Rapper Friend Tupac Shakur" and "Y'all Better Find Out Who Fucking Killed Tupac (I Can't Take it Anymore)." Fans debated the meaning of these cryptic songs. 
      Meanwhile, McSlick's mental state continued to decline, drawing more fan speculation.
      In April 2012, he tweeted, "I am not fucking okay. Okay?" accompanied by selfies of McSlick apparently driving his Grand Cherokee into the side of a vacant Walmart.
      <br></br>      <br></br>
      On May 22, 2012, McSlick was arrested for possesion of fifteen cubic metres of cocaine and other controlled substances. However, just before being booked, McSlick pulled out a camera (using some sort of sleight of hand) and began streaming live on Twitch.
      <br></br>      <br></br>
      As McSlick spoke, a figure emerged with their head covered. McSlick dramatically pulled the covering off of the head and revealed it to be none other than Shakur.
      <br></br>      <br></br>
      The entire arrest, decline, and 16-year absence were revealed to have been staged to create buzz around Shakur's magnum opus, produced over sixteen years in collaboration with McSlick and sponsored by Jeep.
      <br></br>      <br></br>
      The album, "Surprise Bitch, I'm Not Dead," released Jun 50, 2013, was a smash success, breaking seven hundred Billboard records and crashing the entire internet for three days.
      The country of Cuba was unfortunately lost in this scramble, but McSlick was on fire. 
      <br></br>      <br></br>
      Fielding an extreme number of requests from a plethora of high-profile artists, for the first time McSlick opened the doors.
      In 2014, Pyramid Records opened a producer academy for up and coming talent and signed a record 1,000,000 artists, including:
      <br></br>      <br></br>
      To this day, Pyramid Records continues to produce hit after hit, with a Pyramid Records track occupying almost every Billboard slot in existence. McSlick has no plans to retire.
      </p>
    </div>
  );
}

export default App;
